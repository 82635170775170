import { defineMessages, MessageDescriptor } from 'react-intl'
import { ContentType, Type } from '../graphql/generated/autogenerated'
import { SupportedType } from '../types'

export default defineMessages({
    [ContentType.Text]: {
        defaultMessage: '{duration} min read',
        description:
            'The amount of time in minutes (abbreviated) estimated to read an piece of content'
    },
    [ContentType.Video]: {
        defaultMessage: '{duration} min watch',
        description:
            'The duration of time in minutes (abbreviated) to watch a video'
    },
    [ContentType.Audio]: {
        defaultMessage: '{duration} min listen',
        description:
            'The duration of time in minutes (abbreviated) to listen to a piece of audio'
    },
    [Type.RoleModel]: {
        defaultMessage: 'Role Model',
        description: 'Label for role model search result'
    },
    [Type.Article]: {
        defaultMessage: 'Article',
        description: 'Label for article search result'
    },
    [Type.Reset]: {
        defaultMessage: 'Reset',
        description: 'Label for reset search result'
    },
    [Type.Recipe]: {
        defaultMessage: 'Recipe',
        description: 'Label for a recipe search result'
    },
    [Type.CourseModule]: {
        defaultMessage: 'Course Module',
        description: 'Label for a course module search result'
    },
    [Type.CourseLesson]: {
        defaultMessage: 'Course Lesson',
        description: 'Label for a course lesson search result'
    },
    [Type.Microstep]: {
        defaultMessage: 'Microstep',
        description: 'Label for a microstep search result'
    },
    [Type.PodcastEpisode]: {
        defaultMessage: 'Podcast Episode',
        description: 'Label for a podcast episode search result'
    },
    [Type.Podcast]: {
        defaultMessage: 'Podcast',
        description: 'Label for a podcast search result'
    },
    [Type.Course]: {
        defaultMessage: 'Course',
        description: 'Label for a course search result'
    },
    [Type.WatchMeThrive]: {
        defaultMessage: 'Watch Me Thrive',
        description: 'Label for a watch me thrive search result'
    },
    [Type.Book]: {
        defaultMessage: 'Book',
        description: 'Label for a book search result'
    },
    [Type.ResetStockAudio]: {
        defaultMessage: 'Reset Audio',
        description: 'Label for a reset audio result'
    }
} as Record<ContentType | SupportedType, MessageDescriptor>)

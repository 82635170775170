/**
 * Compares two values and returns a number indicating whether the first value is less than, greater than, or equal to the second value
 * @param a A value to compare
 * @param b A value to compare
 * @returns a number indicating whether the first value is less than, greater than, or equal to the second value
 */
export default <T extends object>(field: keyof T) => {
    return (a: T, b: T) => {
        if (a[field] < b[field]) {
            return -1
        }
        if (a[field] > b[field]) {
            return 1
        }
        return 0
    }
}

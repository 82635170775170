import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useGetAlgoliaKeyQuery } from '../graphql/generated/autogenerated'
import { ThriveAlgoliaClient } from '../utils'

interface DecodedToken {
    subscriptionId: string
}

const useAlgoliaClient = () => {
    const thriveToken = useSelector(
        (state: RootStateOrAny) => state?.auth?.thriveToken
    )

    const { locale } = useIntl()
    const { data, loading } = useGetAlgoliaKeyQuery()
    const [client, setClient] = useState<ThriveAlgoliaClient>()
    const userId = useSelector((state: RootStateOrAny) => state?.user?.userId)

    useEffect(() => {
        if (!loading && data?.search?.getAlgoliaKey && thriveToken) {
            try {
                const { subscriptionId } = jwtDecode<DecodedToken>(thriveToken)
                setClient(
                    new ThriveAlgoliaClient(
                        data.search.getAlgoliaKey,
                        subscriptionId,
                        userId
                    )
                )
            } catch (error: unknown) {
                console.error('Error decoding JWT', error)
            }
        }
    }, [data, thriveToken, loading, userId])

    useEffect(() => {
        client?.setLocale(locale)
    }, [locale, client])

    return client?.searchClient
}

export default useAlgoliaClient

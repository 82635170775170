import { Type } from '../graphql/generated/autogenerated'

export { default as ALGOLIA_STYLE_OVERRIDES } from './styles'
export { default as INTL_LABELS } from './intlLabels'

export const SEARCH_QUERY_PARAMS = ['query', 'page']

export const LIBRARY_SEARCH_URL = '/learn/search'
export const LEARN_RESET_NEW_URL = '/learn/reset/new'
export const RESET_NEW_URL = '/reset/new'
export const RESET_EDIT_URL = '/reset/edit'

export const SEARCH_QUERY_PARAM_REFINEMENTS_LIST_ITEMS = ['type']
export const FALLBACK_IMAGE_URL =
    'https://assets.thriveglobal.com/journeys/journey_onboarding_placeholder.png'
export const HITS_PER_PAGE = 12
export const DEBOUNCE_TIMEOUT_MS = 400
export const EMPTY_HIT_RESULT = {
    hits: [],
    page: 1,
    nbHits: 0,
    nbPages: 1,
    processingTimeMS: 0,
    exhaustiveNbHits: true,
    hitsPerPage: HITS_PER_PAGE,
    query: '',
    params: ''
}
export const ALGOLIA_INDEX = 'CMS'

export const LIBRARY_SUPPORTED_TYPES = [
    Type.Article,
    Type.RoleModel,
    Type.Recipe,
    Type.CourseModule,
    Type.Reset,
    Type.PodcastEpisode,
    Type.Podcast,
    Type.Course,
    Type.WatchMeThrive,
    Type.Book
] as const

export const RESET_SUPPORTED_TYPES = [Type.ResetStockAudio] as const

export const SEARCH_SUPPORTED_TYPES = [
    ...LIBRARY_SUPPORTED_TYPES,
    Type.Microstep
] as const

export const SUPPORTED_TYPES = [
    ...SEARCH_SUPPORTED_TYPES,
    ...RESET_SUPPORTED_TYPES
] as const

export const ALGOLIA_REFINEMENTS_ATTRIBUTE = 'type'
export const REFERRER_NAME = 'search'

import {
    SEARCH_QUERY_PARAMS,
    SEARCH_QUERY_PARAM_REFINEMENTS_LIST_ITEMS
} from '../constants'
import filterBySupportedTypes from './filterBySupportedTypes'

export default (queryString = window.location.search) => {
    const params = new URLSearchParams(queryString)

    const state = SEARCH_QUERY_PARAMS.reduce((acc, queryParam) => {
        const val = params.get(queryParam)
        if (val) {
            acc[queryParam] = val
        }
        return acc
    }, {} as Record<string, string | object>)

    SEARCH_QUERY_PARAM_REFINEMENTS_LIST_ITEMS.forEach((param) => {
        const val = params.get(param)
        if (val) {
            const refinementValues = val
                .split(',')
                .filter(filterBySupportedTypes)
            if (typeof state.refinementList === 'object') {
                state.refinementList = {
                    ...state.refinementList,
                    [param]: refinementValues
                }
            } else {
                state.refinementList = {
                    [param]: refinementValues
                }
            }
        }
    })

    return state
}

import { CMSHit, Snippet } from '../types'

/**
 * Searches within a Hit's searchableAtrributes to see if any snippets were found and returns the property if so
 * @param hit An Algolia Hit result
 * @returns a string representing a nested property name within searchable attributes where a search snippet was found
 */
export default (
    hit: CMSHit
): keyof CMSHit['searchableAttributes'] | undefined => {
    let result

    Object.keys(hit._snippetResult.searchableAttributes).some(
        (key: keyof CMSHit['searchableAttributes']) => {
            const match = (
                hit._snippetResult.searchableAttributes[key] as Snippet[]
            ).findIndex((item) => item.matchLevel === 'full')

            if (match > -1) {
                result = `${key}.${match}`
                return true
            }
        }
    )

    return result
}

import { ContentType, Type } from '../graphql/generated/autogenerated'
import { SUPPORTED_TYPES } from '../constants'

export type NonUndefined<T> = T extends undefined ? never : T
export type Nullable<T> = T | null
type NonEmptyArray<T> = T[] & { 0: T }

export type QuantityType =
    | 'SECONDS'
    | 'MINUTES'
    | 'HOURS'
    | 'EPISODES'
    | 'MODULES'
    | 'DAYS'

export type CountableType = Extract<
    QuantityType,
    'EPISODES' | 'MODULES' | 'DAYS'
>

export function isCountableType(
    quantityType: QuantityType
): quantityType is CountableType {
    return ['EPISODES', 'MODULES', 'DAYS'].includes(
        quantityType as CountableType
    )
}

export type DurationType = Extract<
    QuantityType,
    'SECONDS' | 'MINUTES' | 'HOURS'
>

export function isDurationType(
    quantityType: QuantityType
): quantityType is DurationType {
    return ['SECONDS', 'MINUTES', 'HOURS'].includes(
        quantityType as DurationType
    )
}

export type CompleteContentType = `${Type}_${ContentType}`

export type SupportedType = Extract<Type, (typeof SUPPORTED_TYPES)[number]>

export type FeaturedImage = {
    url: string
}

export type Snippet = {
    matchLevel: 'none' | 'full'
    value: string
}

export type Parent = {
    id: string
    uuid: string
    type: Type
    parents?: Parent[]
}

/*** ARTICLES ***/
export type SearchableArticleAttributes = 'body' | 'subtitle' | 'author'

export type ArticleHit = ThriveHit<SearchableArticleAttributes> & {
    type: Type.Article | Type.Recipe | Type.RoleModel
    quantityType: DurationType
}

export function isArticle(h: ThriveHit): h is ArticleHit {
    return [Type.Article, Type.RoleModel, Type.Recipe].includes(h.type)
}

/*** RESETS ***/
export type SearchableResetAttributes = 'captions' | 'description'

export type ResetHit = ThriveHit<SearchableResetAttributes> & {
    type: Type.Reset
    quantityType: DurationType
    contentType: ContentType.Audio | ContentType.Video
}

export function isReset(h: ThriveHit): h is ResetHit {
    return h.type === Type.Reset
}

/*** COURSE_MODULES ***/
export type SearchableCourseModuleAttributes = 'description'

export type CourseModuleHit = ThriveHit<SearchableCourseModuleAttributes> & {
    type: Type.CourseModule
    parents: NonEmptyArray<{
        id: string
        uuid: string
        type: Type.CourseLesson
        parents: NonEmptyArray<{
            id: string
            type: Type.Course
            uuid: string
        }>
    }>
    id: string
}

export function isCourseModule(h: ThriveHit): h is CourseModuleHit {
    return h.type === Type.CourseModule
}

/*** MICROSTEPS ***/
export type SearchableMicrostepAttributes = 'body'

export type MicrostepHit = ThriveHit<SearchableMicrostepAttributes> & {
    type: Type.Microstep
}

export function isMicrostep(h: ThriveHit): h is MicrostepHit {
    return h.type === Type.Microstep
}

/*** PODCAST EPISODES ***/

export type SearchablePodcastEpisodeAttribtues = 'summary'

export type PodcastEpisodeHit =
    ThriveHit<SearchablePodcastEpisodeAttribtues> & {
        type: Type.PodcastEpisode
        parents: NonEmptyArray<{
            id: string
            uuid: string
            type: Type.PodcastSeason
            parents: NonEmptyArray<{
                id: string
                uuid: string
                type: Type.Podcast
            }>
        }>
    }

export function isPodcastEpisode(h: ThriveHit): h is PodcastEpisodeHit {
    return h.type === Type.PodcastEpisode
}

/*** PODCAST ***/

export type SearchablePodcastAttributes = 'summary'

export type PodcastHit = ThriveHit<SearchablePodcastAttributes> & {
    type: Type.Podcast
    id: string
}

export function isPodcast(h: ThriveHit): h is PodcastHit {
    return h.type === Type.Podcast
}

/*** COURSE ***/

export type SearchableCourseAttributes =
    | 'about'
    | 'author'
    | 'blurbs'
    | 'subtitle'

export type CourseHit = ThriveHit<SearchableCourseAttributes> & {
    type: Type.Course
    id: string
}

export function isCourse(h: ThriveHit): h is CourseHit {
    return h.type === Type.Course
}

/*** WATCH ME THRIVE ***/

export type SearchableWatchMeThriveAttributes = 'author' | 'body' | 'subtitle'

export type WatchMeThriveHit = ThriveHit<SearchableWatchMeThriveAttributes> & {
    type: Type.WatchMeThrive
    id: string
}

export function isWatchMeThrive(h: ThriveHit): h is WatchMeThriveHit {
    return h.type === Type.WatchMeThrive
}

/*** BOOKS ***/
export type SearchableBookAttributes = 'author' | 'subtitle'

export type BookHit = ThriveHit<SearchableBookAttributes> & {
    type: Type.Book
    id: string
}

export function isBook(h: ThriveHit): h is BookHit {
    return h.type === Type.Book
}

/*** RESET STOCK AUDIO ***/
export type SearchableResetAudioAttributes = 'title' | 'subtitle'

export type ResetAudioHit = ThriveHit<SearchableResetAudioAttributes> & {
    type: Type.ResetStockAudio
    id: string
}

export function isResetAudio(h: ThriveHit): h is ResetAudioHit {
    return h.type === Type.ResetStockAudio
}

export type CMSHit =
    | ArticleHit
    | ResetHit
    | CourseModuleHit
    | MicrostepHit
    | PodcastEpisodeHit
    | PodcastHit
    | CourseHit
    | WatchMeThriveHit
    | BookHit
    | ResetAudioHit

export type ThriveHit<T extends string = string> = {
    objectID: string
    __position: number
    title: string
    uuid: string
    id?: string
    quantity: number
    quantityType: QuantityType
    contentType: ContentType
    type: Type
    parents?: Parent[]
    featuredImage: Nullable<FeaturedImage>
    searchableAttributes: Record<T, string[]>
    filterableAttributes: Record<string, string[]>
    _snippetResult: {
        searchableAttributes: Record<T, Snippet[]>
    }
    __queryID: string
}
export type RefinementListItem = {
    value: string | string[]
    label: string
    displayedLabel: string
    highlighted?: string
    count: number
    isRefined: boolean
}

export type SearchState = {
    query?: string
    page?: string
    refinementList?: Record<string, string[]>
    isSuggestion?: boolean
}

export default `
    .ais-Snippet-highlighted,
    .ais-Highlight-highlighted {
        font-style: normal;
        color: inherit;
        font-weight: inherit;
        background: #0000001f;
    }

    span.ais-Snippet::after {
        content: '...';
    }
`

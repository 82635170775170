import { ALGOLIA_STYLE_OVERRIDES } from '../constants'

export default () => {
    const head = document.getElementsByTagName('head')[0]
    const style = document.createElement('style')
    style.type = 'text/css'
    style.id = 'ais-overrides'
    style.appendChild(document.createTextNode(ALGOLIA_STYLE_OVERRIDES))
    head.appendChild(style)
}

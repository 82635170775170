import {
    SEARCH_QUERY_PARAMS,
    SEARCH_QUERY_PARAM_REFINEMENTS_LIST_ITEMS
} from '../constants'

export default (searchState: Record<string, string | object | boolean>) => {
    const params = SEARCH_QUERY_PARAMS.reduce((acc, param) => {
        if (searchState[param]) {
            acc.push(`${param}=${searchState[param]}`)
        }
        return acc
    }, [] as string[])

    SEARCH_QUERY_PARAM_REFINEMENTS_LIST_ITEMS.forEach((param) => {
        const refinementList = searchState.refinementList as Record<
            string,
            string[]
        >

        if (refinementList) {
            params.push(`${param}=${refinementList[param].join(',')}`)
        }
    })
    return params
}

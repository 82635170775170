import type { QuantityType } from '../types'

export default (quantity: number, quantityType: QuantityType) => {
    let multiplier: number

    switch (quantityType) {
        case 'SECONDS':
            multiplier = 1 / 60
            break
        case 'HOURS':
            multiplier = 60
            break
        case 'MINUTES':
            multiplier = 1
            break
        default:
            return NaN
    }

    const durationInMinutes = Math.max(1, Math.floor(quantity * multiplier))

    return durationInMinutes
}

import {
    CMSHit,
    isArticle,
    isBook,
    isCourse,
    isCourseModule,
    isMicrostep,
    isPodcast,
    isPodcastEpisode,
    isReset,
    isWatchMeThrive,
    isResetAudio
} from '../types'

export default (hit: CMSHit): string => {
    if (
        isArticle(hit) ||
        isCourse(hit) ||
        isWatchMeThrive(hit) ||
        isResetAudio(hit)
    ) {
        return hit.searchableAttributes.subtitle[0]
    } else if (isReset(hit) || isCourseModule(hit)) {
        return hit.searchableAttributes.description[0]
    } else if (isMicrostep(hit)) {
        return hit.searchableAttributes.body[0]
    } else if (isPodcastEpisode(hit) || isPodcast(hit)) {
        return hit.searchableAttributes.summary[0]
    } else if (isBook(hit)) {
        return hit.searchableAttributes.subtitle[0]
    }

    // This should fail if we're not covering any types in the above check
    return hit
}

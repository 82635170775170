import { ContentType } from '../graphql/generated/autogenerated'
import { IconName } from '@fortawesome/fontawesome-common-types'

export default (contentType: ContentType): IconName => {
    switch (contentType) {
        case ContentType.Video:
            return 'video'
        case ContentType.Audio:
            return 'headphones'
        case ContentType.Text:
        default:
            return 'align-left'
    }
}

import { LEARN_RESET_NEW_URL } from '../constants'
import { ContentType } from '../graphql/generated/autogenerated'
import {
    CMSHit,
    isArticle,
    isBook,
    isCourse,
    isCourseModule,
    isMicrostep,
    isPodcast,
    isPodcastEpisode,
    isReset,
    isResetAudio,
    isWatchMeThrive
} from '../types'

export default (hit: CMSHit, queryString = '') => {
    if (isArticle(hit) || isWatchMeThrive(hit)) {
        return `/learn/articles/${hit.uuid}${queryString}`
    } else if (isReset(hit)) {
        return hit.contentType === ContentType.Audio
            ? `/reset/thrive/audio/${hit.uuid}${queryString}`
            : `/reset/thrive/${hit.uuid}${queryString}`
    } else if (isMicrostep(hit)) {
        return `/today/microsteps/${hit.uuid}${queryString}`
    } else if (isPodcast(hit)) {
        return `/learn/podcasts/${hit.id}${queryString}`
    } else if (isCourse(hit)) {
        return `/learn/courses/${hit.id}${queryString}`
    } else if (isPodcastEpisode(hit)) {
        return `/learn/podcasts/${
            hit?.parents?.[0]?.parents?.[0]?.id
        }?episode=${hit.id}&time=0${queryString.replace('?', '&')}`
    } else if (isCourseModule(hit)) {
        return `/learn/courses/${hit.parents?.[0]?.parents?.[0]?.id}?module=${
            hit.id
        }&autoplay=1${queryString.replace('?', '&')}`
    } else if (isBook(hit)) {
        return `/learn/books/${hit.uuid}${queryString}`
    } else if (isResetAudio(hit)) {
        return LEARN_RESET_NEW_URL
    }

    // This should fail if we're not covering any types in the above checks
    return hit
}

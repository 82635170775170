const configurations: Record<string, string | null> = {
    'algolia-account-id': (process.env.ALGOLIA_ACCOUNT_ID as string) || null,
    'algolia-host': process.env.ALGOLIA_QUERY_HOST || null,
    env: (process.env.ENVIRONMENT as string) || null
}

export default function config(name: string, defaultValue = null) {
    if (name in configurations) return configurations[name]
    else return defaultValue
}

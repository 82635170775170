import { AlgoliaSearchOptions } from 'algoliasearch'
import algoliasearch, { SearchClient } from 'algoliasearch/lite'
import aa from 'search-insights'
import config from '../config'
import {
    EMPTY_HIT_RESULT,
    HITS_PER_PAGE,
    RESET_NEW_URL,
    RESET_EDIT_URL,
    RESET_SUPPORTED_TYPES,
    SEARCH_SUPPORTED_TYPES
} from '../constants'

const ALGOLIA_ACCOUNT_ID = config('algolia-account-id')
const ALGOLIA_QUERY_HOST = config('algolia-host')

const searchOptions: AlgoliaSearchOptions = ALGOLIA_QUERY_HOST
    ? {
          hosts: [ALGOLIA_QUERY_HOST]
      }
    : {}

/**
 * ThriveAlgoliaClient is a custom Algolia search client that integrates
 * with Algolia and Search Insights. It provides extended search functionality
 * with additional filtering based on locale, subscription IDs, and supported types.
 */
class ThriveAlgoliaClient {
    searchClient: SearchClient
    private locale: string
    private subscriptionId: string

    /**
     * Constructs the ThriveAlgoliaClient instance.
     *
     * @param {string} apiKey - The Algolia API key.
     * @param {string} subscriptionId - The subscription ID used for filtering search results.
     * @param {string} userId - The user ID for setting up Search Insights.
     * @param {string} [locale='en-US'] - The locale used for filtering search results.
     */
    constructor(
        apiKey: string,
        subscriptionId: string,
        userId: string,
        locale = 'en-US'
    ) {
        this.locale = locale
        this.subscriptionId = subscriptionId

        const algoliaClient = algoliasearch(
            String(ALGOLIA_ACCOUNT_ID),
            apiKey,
            searchOptions
        )

        aa('init', {
            appId: String(ALGOLIA_ACCOUNT_ID),
            apiKey: apiKey
        })
        aa('setUserToken', userId)

        const isInResetSearch =
            location.pathname.includes(RESET_NEW_URL) ||
            location.pathname.includes(RESET_EDIT_URL)

        const supportedTypes = isInResetSearch
            ? RESET_SUPPORTED_TYPES
            : SEARCH_SUPPORTED_TYPES

        // Custom search function that injects locale and subscription-based filtering into queries
        this.searchClient = {
            ...algoliaClient,
            search: (queries, requestOptions) => {
                if (queries[0].params?.query === '') {
                    return Promise.resolve({
                        results: [
                            {
                                ...EMPTY_HIT_RESULT,
                                hitsPerPage:
                                    queries[0].params.hitsPerPage ||
                                    HITS_PER_PAGE
                            }
                        ]
                    })
                }
                const thriveQueries: typeof queries = queries.map((query) => {
                    const existingFilters = query?.params?.filters || ''
                    const presetFilter = `locale:${
                        this.locale
                    } AND NOT excludedSubscriptionIds:${
                        this.subscriptionId
                    } AND (accessPolicy:EXCLUDE_SUBSCRIPTIONS OR accessPolicy:UNRESTRICTED OR includedSubscriptionIds:${
                        this.subscriptionId
                    }) AND (${supportedTypes
                        .map((type) => `type:${type}`)
                        .join(' OR ')})`

                    const updatedFilter = `${presetFilter}${
                        existingFilters.length
                            ? ` AND (${existingFilters})`
                            : ''
                    }`

                    return {
                        ...query,
                        params: {
                            ...query.params,
                            filters: updatedFilter
                        }
                    }
                })
                return algoliaClient.search(thriveQueries, requestOptions)
            }
        }
    }

    /**
     * Sets the locale used for filtering search results.
     *
     * @param {string} locale - The new locale to be set.
     */
    setLocale(locale: string) {
        this.locale = locale
    }
}

export default ThriveAlgoliaClient

import React from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary, FullAppFallback } from '@thriveglobal/thrive-web-errors'

import singleSpaReact from 'single-spa-react'
import Root from './root.component'

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(err, info, props) {
        return (
            <ErrorBoundary
                boundaryName="AppContainer"
                boundaryType={'MicroFrontend'}
                packageName={process.env.APP_NAME!}
                packageOwner={process.env.PROJECT_OWNER!}
                packageVersion={process.env.APP_VERSION!}
                errorOnMount={err}
                fallbackComponent={FullAppFallback}
            />
        )
    }
})

export const { bootstrap, mount, unmount } = lifecycles
export * from './shared'
